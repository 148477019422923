<template>
  <div :class="wrapperClassSetting">
    <div :class="multiselectWrapperClass" style="min-width: 100px">
      <label for="operating-unit" :class="labelClass" style="width: 45.7%"
        >Operating Unit</label
      >
      <multiselect
        v-model="selectedOperatingUnit"
        id="operating-unit"
        :options="this.$store.state.operatingUnit.filter.operatingUnit"
        :multiple="true"
        :allow-empty="true"
        :group-select="false"
        :max-height="120"
        :limit="3"
        placeholder="Select operating unit"
        track-by="ID"
        :custom-label="queryOperatingUnit"
        class="h-full"
      >
        <template slot="option" slot-scope="props">
          <div class="option__desc">
            <span class="option__title">
              <span class="option__title"
                >({{ props.option.Code }}), {{ props.option.Name }}</span
              >
            </span>
          </div>
        </template>

        <template slot="tag" slot-scope="props">
          <div class="multiselect__tag">
            <span>{{ props.option.Name }}</span>
            <i
              class="multiselect__tag-icon"
              @click.prevent
              @mousedown.prevent.stop="props.remove(props.option, $event)"
            />
          </div>
        </template>
      </multiselect>
    </div>

    <div :class="multiselectWrapperClass" style="min-width: 100px">
      <label for="teritory" :class="labelClass" style="width: 45.7%"
        >Teritory</label
      >
      <multiselect
        id="teritory"
        v-model="selectedTerritory"
        :options="this.$store.state.operatingUnit.filter.territory"
        :multiple="multipleTerritory"
        :allow-empty="true"
        :group-select="false"
        :max-height="120"
        :limit="3"
        placeholder="Select teritory"
        track-by="TerritoryID"
        label="TerritoryName"
        :custom-label="queryTerritory"
        class="h-full"
      >
        <template slot="option" slot-scope="props">
          <div class="option__desc">
            <span class="option__title">
              <span class="option__title"
                >({{ props.option.TerritoryCode }}),
                {{ props.option.TerritoryName }}</span
              >
            </span>
          </div>
        </template>

        <template slot="tag" slot-scope="props">
          <div class="multiselect__tag">
            <span>{{ props.option.TerritoryName }}</span>
            <i
              class="multiselect__tag-icon"
              @click.prevent
              @mousedown.prevent.stop="props.remove(props.option, $event)"
            />
          </div>
        </template>
      </multiselect>
    </div>

    <div v-if="!hideShowButton" class="w-full flex justify-end">
      <vs-button @click="onShow">Show</vs-button>
    </div>
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";
// import "vue-multiselect/dist/vue-multiselect.min.css";
export default {
  props: {
    full: Boolean,
    hideShowButton: {
      type: Boolean,
      default: false,
    },
    multipleTerritory: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    multiselect: Multiselect,
  },

  data() {
    return {
      // selectedOperatingUnit: [],
      // selectedTerritory: [],
      wrapperClassSetting: "flex flex-col gap-4",
      multiselectWrapperClass: "flex",
      labelClass: "",
    };
  },
  mounted() {
    // const operatingUnits = this.$store.state.operatingUnit.filter.operatingUnit;
    // const territory = this.$store.state.operatingUnit.filter.territory;

    // this.selectedOperatingUnit = [operatingUnits[0]];
    // this.selectedTerritory = [territory[0]];

    if (this.multipleTerritory) {
      const selectedTerritory =
        this.$store.state.operatingUnit.filter.selectedTerritories;
      if (selectedTerritory.length === 0) {
        this.$store.commit(
          "operatingUnit/setSelectedTerritories",
          this.$store.state.operatingUnit.filter.territory[0]
        );
      }
    } else {
      const selectedTerritory =
        this.$store.state.operatingUnit.filter.selectedTerritory;
      if (Object.keys(selectedTerritory).length === 0) {
        this.$store.commit(
          "operatingUnit/setSelectedTerritory",
          this.$store.state.operatingUnit.filter.territory[0]
        );
      }
    }

    if (!this.hideShowButton) {
      this.wrapperClassSetting += " mb-5";
    }
  },
  created() {
    this.$store.watch(
      (state) => state.operatingUnit.filter.selectedOperatingUnits,
      () => {
        this.$store.commit("operatingUnit/setTerritoryFilterByOperatingUnits");
      }
    );

    if (!this.full) {
      this.wrapperClassSetting += " w-2/3";
      this.multiselectWrapperClass += " flex-col";
      this.labelClass += "mb-2";
    } else {
      this.wrapperClassSetting += " w-full";
      this.multiselectWrapperClass +=
        " flex-row gap-6 justify-between items-center";
      this.labelClass += "";
    }
  },
  methods: {
    onOperatingUnitChange(value) {
      const operatingUnits =
        this.$store.state.operatingUnit.filter.operatingUnit;
      if (value.length === 0) {
        this.selectedOperatingUnit = [operatingUnits[0]];
        this.$store.commit(
          "operatingUnit/setSelectedOperatingUnits",
          this.selectedOperatingUnit
        );
        return;
      }
      if (value[0].ID === 0) {
        value.shift();
        this.$store.commit("operatingUnit/setSelectedOperatingUnits", value);
        this.selectedOperatingUnit = value;
      } else if (value.some((ou) => ou.ID === 0)) {
        this.selectedOperatingUnit = [{ ID: 0, Code: "All", Name: "All" }];
        this.$store.commit(
          "operatingUnit/setSelectedOperatingUnits",
          this.selectedOperatingUnit
        );
      } else {
        this.selectedOperatingUnit = value;
        this.$store.commit(
          "operatingUnit/setSelectedOperatingUnits",
          this.selectedOperatingUnit
        );
      }
    },
    onTeritoryChange(value) {
      if (value.length === 0) {
        const allValue = [
          { ID: 0, TerritoryCode: "All", TerritoryName: "All" },
        ];
        this.selectedTerritory = this.$store.commit(
          "operatingUnit/setSelectedTerritories",
          allValue
        );
        this.selectedTerritory = allValue;
        return;
      }

      if (value[0].ID === 0) {
        value.shift();
        this.$store.commit("operatingUnit/setSelectedTerritories", value);
        this.selectedTerritory = value;
      } else if (value.some((ou) => ou.ID === 0)) {
        this.selectedTerritory = [
          { ID: 0, TerritoryCode: "All", TerritoryName: "All" },
        ];
        this.$store.commit(
          "operatingUnit/setSelectedTerritories",
          this.selectedTerritory
        );
      } else {
        this.selectedTerritory = value;
        this.$store.commit(
          "operatingUnit/setSelectedTerritories",
          this.selectedTerritory
        );
      }
    },
    onShow() {
      this.$emit("onShow");
    },
    queryOperatingUnit({ Code, Name }) {
      return `(${Code}), ${Name}`;
    },
    queryTerritory({ TerritoryCode, TerritoryName }) {
      return `(${TerritoryCode}), ${TerritoryName}`;
    },
  },
  computed: {
    selectedOperatingUnit: {
      get() {
        return this.$store.state.operatingUnit.filter.selectedOperatingUnits;
      },
      set(value) {
        if (value.length === 0) {
          this.$store.commit("operatingUnit/setSelectedOperatingUnits", [
            this.$store.state.operatingUnit.filter.operatingUnit[0],
          ]);
          return;
        }
        if (value[0].ID === 0) {
          value.shift();
          this.$store.commit("operatingUnit/setSelectedOperatingUnits", value);
          // this.selectedOperatingUnit = value;
        } else if (value.some((ou) => ou.ID === 0)) {
          // this.selectedOperatingUnit = [{ ID: 0, Code: "All", Name: "All" }];
          this.$store.commit("operatingUnit/setSelectedOperatingUnits", [
            { ID: 0, Code: "All", Name: "All" },
          ]);
        } else {
          // this.selectedOperatingUnit = value;
          this.$store.commit("operatingUnit/setSelectedOperatingUnits", value);
        }
      },
    },
    selectedTerritory: {
      get() {
        if (this.multipleTerritory) {
          return this.$store.state.operatingUnit.filter.selectedTerritories;
        } else {
          return this.$store.state.operatingUnit.filter.selectedTerritory;
        }
      },
      set(value) {
        if (this.multipleTerritory) {
          if (value.length === 0) {
            this.$store.commit("operatingUnit/setSelectedTerritories", [
              this.$store.state.operatingUnit.filter.territory[0],
            ]);
            return;
          }
          if (value[0].ID === 0) {
            value.shift();
            this.$store.commit("operatingUnit/setSelectedTerritories", value);
            // this.selectedTerritory = value;
          } else if (value.some((ou) => ou.ID === 0)) {
            // this.selectedTerritory = [
            //   { ID: 0, TerritoryCode: "All", TerritoryName: "All" },
            // ];
            this.$store.commit("operatingUnit/setSelectedTerritories", [
              { ID: 0, TerritoryCode: "All", TerritoryName: "All" },
            ]);
          } else {
            // this.selectedTerritory = value;
            this.$store.commit("operatingUnit/setSelectedTerritories", value);
          }
        } else {
          this.$store.commit("operatingUnit/setSelectedTerritory", value);
        }
      },
    },
  },
};

// onTerritoryChange
</script>
