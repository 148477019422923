import { render, staticRenderFns } from "./form-create.vue?vue&type=template&id=d74be4d6&scoped=true&"
import script from "./form-create.vue?vue&type=script&lang=js&"
export * from "./form-create.vue?vue&type=script&lang=js&"
import style0 from "./form-create.vue?vue&type=style&index=0&id=d74be4d6&prod&lang=css&"
import style1 from "./form-create.vue?vue&type=style&index=1&id=d74be4d6&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d74be4d6",
  null
  
)

export default component.exports